
<template>
  <div class="rating-body">
  <span>Media Review</span>
  <NuxtLink
    v-for="item in props.rateList"
    :key="item.id"
    class="rate-item"
    :href="item.link"
    target="_blank"
  >
    <NuxtImg
      :alt="item"
      :src="getImageUrl(item)"
      format="webp"
      height="40"
      width="40"
      target="_blank"
    />
    <div class="text-info-container">
      <span>{{ item.name }}</span>
      <div class="second-line">
        <span>{{ item.rating}}</span>
      </div>
    </div>
  </NuxtLink>
  </div>

</template>
<script setup lang="ts">
import ImageUtil from '@/util/image-util.ts';
const props = defineProps({
  rateList: {
    type: Array,
    required: true
  },
});
const getImageUrl = (item): string => {
  return ImageUtil.getWebpUrl(item.icon);
};
</script>
<style scoped>
.rating-body {
  background-color: #f6f8f7;
  padding: 20px 40px 20px 40px;
  margin-bottom: 100px; /*todo这里遇到一个问题，右侧bar默认滑动不到最底部，所以这里加了一个margin-bottom*/
}

.rating-body span {
  font-size: 24px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.9);
}

.tab-bar-item span {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  position: relative;
}

.tab-bar-item-active span {
  color: rgba(0, 0, 0, 1);
}

.tab-underline {
  height: 2px;
  margin-top: 4px;
  background-color: rgba(0, 0, 0, 1);
}

.rate-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: center;
  text-decoration: none;
}

.rate-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.rate-item img {
  border-radius: 20px;
}

.text-info-container {
  width: 80%;
  display: flex;
  margin-left: 20px;
  justify-content: space-between;
}

.text-info-container span {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
}

.second-line {
  display: flex;
  flex-direction: row;
}

.second-line span {
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  color: rgba(243, 7, 7, 0.904)}

.second-line span:nth-child(2) {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}
.loadMore-button span {
  font-size: 16px;
  color: #279ff6;
  cursor: pointer;
  font-weight: 500;
}

.loadMore-button img {
  width: 20px;
}
</style>

