<script lang="ts" setup>
import TimeUtil from '@/util/time-util.ts';
import { defineProps, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import GameUtil from '@/util/game-util.ts';

const route = useRoute();
const jumpDetail = (item) => {
  if (item === undefined) return;
  let gameName = route.params.game;
  if (gameName == undefined) {
    gameName = GameUtil.defaultGameDetail.key;
  }
  if (item.key != undefined) {
    return `/news/${item.key}`;
  }
  return `/news/${item.id}`;
};

const props = defineProps({
  propRecNewsList: {
    type: Array,
    required: true
  }
});

watchEffect(() => {
  if (props.propRecNewsList) {
  }
});

const getTime = (time: number): string => {
  return TimeUtil.convertTimeMd(time);
};
</script>

<template>
  <div class="rec-news-list-container">
    <div class="rec-news-list-title">
      <span>{{ $t('recoNews') }}</span>
    </div>
    <div class="rec-news-list-content">
      <NuxtLink
        v-for="(item, index) in propRecNewsList"
        :key="index"
        :to="jumpDetail(item)"
        class="rec-news-list-item"
      >
        <div class="rec-news-time-container">
          <span>{{ getTime(item.createTime * 1000) }}</span>
        </div>
        <div class="rec-news-title-container">
          <span>{{ item.title }}</span>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped>
.rec-news-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rec-news-list-title {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.rec-news-list-title span {
  font-size: 24px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.9);
}

.rec-news-list-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.rec-news-list-item {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 32px;
  cursor: pointer;
  text-decoration: none;
}

.rec-news-list-item:first-child {
  margin-top: 8px;
}

.rec-news-list-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.rec-news-time-container {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(39, 159, 246, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.rec-news-time-container span {
  font-size: 12px;
  font-weight: 600;
  color: #279ff6;
}

.rec-news-title-container {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.rec-news-title-container span {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
</style>
