<template>
  <div class="rec-news-container">
    <div class="rec-news-profile">
      <RecNewsProfile :prop-top-news-list="propTopNewsList" />
    </div>
    <div class="rec-news-list">
      <RecNewsList :prop-rec-news-list="propRecNewsList" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, watchEffect } from 'vue';
import RecNewsList from '~/components/games/homepage/recNews/rec-news-list.vue';
import RecNewsProfile from '~/components/games/homepage/recNews/rec-news-profile.vue';

const props = defineProps({
  propTopNewsList: {
    type: Array,
    required: false,
    default: null
  },
  propRecNewsList: {
    type: Array,
    required: false,
    default: null
  }
});

watchEffect(() => {
  if (props.propTopNewsList) {
  }
});
</script>

<style scoped>
.rec-news-container {
  display: flex;
  gap: 16px;
  padding: 0px 40px 0px 40px;
  height: 420px;
}

.rec-news-profile {
  flex: 1;
  height: 100%;
}

.rec-news-list {
  flex: 1;
  height: 100%;
}

@media screen and (max-width: 999px) {
  .rec-news-container {
    display: none;
  }
}
</style>
