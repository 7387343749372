<script setup lang="ts">
import TimeUtil from '@/util/time-util.ts';
import { defineProps, watchEffect } from 'vue';
import ImageUtil from '@/util/image-util.ts';
import { useRoute } from 'vue-router';
import GameUtil from '@/util/game-util.ts';

const route = useRoute();

const props = defineProps({
  propTopNewsList: {
    type: Array,
    required: true
  }
});

const getTime = (time: number): string => {
  const convertedTime = TimeUtil.convertTime(time * 1000);
  return convertedTime;
};

const jumpDetail = (item) => {
  if (item === undefined) return;
  let gameName = route.params.game;
  if (gameName == undefined) {
    gameName = GameUtil.defaultGameDetail.key;
  }
  if (item.key != undefined) {
    return `/news/${item.key}`;
  }
  return `/news/${item.id}`;
};

watchEffect(() => {
  if (props.propTopNewsList) {
  }
});

const getImageUrl = (item): string => {
  if (item == undefined || item.image == null || item.image.url == null) {
    return ImageUtil.getLocalImgFromCdn('ic_image_placeholder');
  }
  return ImageUtil.getWebpUrl(item.image.url, 300);
};
</script>

<template>
  <NuxtLink
    class="rec-news-profile-container"
    :to="jumpDetail(propTopNewsList[0])"
  >
    <div v-if="!propTopNewsList"></div>
    <div v-if="propTopNewsList.length > 0">
      <NuxtImg
        style="object-fit:initial;"
        format="webp"
        :alt="propTopNewsList[0].title"
        :src="getImageUrl(propTopNewsList[0])"
      />
      <div class="rec-news-profile-bottom">
        <div class="text">
          <span>{{ propTopNewsList[0].title }}</span>
          <span>{{ getTime(propTopNewsList[0].createTime) }}</span>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<style scoped>
.rec-news-profile-container {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
}
.rec-news-profile-container:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}
.rec-news-profile-container img {
  height: 100%;
  width: 800px;
  max-width: 100%;
  object-fit: cover;
}
.rec-news-profile-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
}
.rec-news-profile-bottom .text {
  height: 80px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 10px;
  padding-left: 10px;
}
.rec-news-profile-bottom .text span:nth-child(1) {
  font-size: 16px;
  font-weight: 600;
  color: white;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.rec-news-profile-bottom .text span:nth-child(2) {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 5px;
}
</style>
