<script lang="ts" setup>
import ImageUtil from '~/util/image-util.ts';
import TimeUtil from '~/util/time-util.ts';
import NumUtil from '~/util/num-util.ts';

const isMobileRef = computed(() => {
  return useIsMobileState().value;
});

const props = defineProps({
  list: {
    type: Object,
    required: true
  },
  showNum: {
    type: Boolean,
    required: false,
    default: false
  },
  showHotEvent: {
    type: Boolean,
    required: false,
    default: false
  },
  needFilterDuplicate: {
    type: Boolean,
    required: false,
    default: false
  }
});
const emit = defineEmits(['clickItem', 'clickHotEvent']);

const clickItem = (item) => {
  emit('clickItem', item);
};

const clickHotEvent = (theme) => {
  emit('clickHotEvent', theme);
};

const canItemShow = (needFilterDuplicate, item) => {
  if (needFilterDuplicate) {
    return item.websiteLink.startsWith('https://www.ign.com'); //todo modify
  }
  return true;
};

const getSortNumStyle = (index) => {
  if (index < 3) {
    const colors = ['#F14749', '#FF5B03', '#FBB903'];
    return `background-color: ${colors[index]}`;
  } else {
    return 'background-color: #131F28';
  }
};
</script>

<template>
  <div class="article-list">
    <div v-for="(item, index) in props.list" :key="index">
      <div
        v-if="canItemShow(props.needFilterDuplicate, item)"
        class="articles-item"
        @click="clickItem(item)"
      >
        <div class="left">
          <NuxtImg
            :alt="item.title"
            :placeholder="
              ImageUtil.getLocalImgFromCdn('ic_rss_article_placeholder')
            "
            :src="
              isMobileRef
                ? ImageUtil.getArticleImageUrl(item.image.url, 200)
                : ImageUtil.getArticleImageUrl(item.image.url, 300)
            "
            loading="lazy"
            format="webp"
            quality="60"
          />
          <div
            v-if="index < 99 && props.showNum"
            :style="getSortNumStyle(index)"
            class="sort-num"
          >
            {{ index + 1 }}
          </div>
        </div>

        <div class="articles-content">
          <span class="articles-title">{{ item.title }}</span>
          <span class="articles-desc">{{ item.description }}</span>
          <div class="extra-info">
            <span
              v-if="item.ext.rssSource?.type === 'RSS_TYPE_STEAM'"
              class="articles-source"
            >
              Steam
            </span>
            <span
              v-if="item.ext.rssSource?.alias.length > 0"
              class="articles-source"
            >
              {{ item.ext.rssSource.alias }}
            </span>
            <span class="articles-time">
              {{ TimeUtil.convertTime(item.createTime * 1000) }}
            </span>
            <div
              v-if="item.ext.relatedMainTheme != null && props.showHotEvent"
              class="relative-theme"
              @click.stop="clickHotEvent(item.ext.relatedMainTheme)"
            >
              <NuxtImg
                :src="ImageUtil.getLocalImgFromCdn('ic_rss_hot')"
                alt="popular event"
              />
              <span>{{ $t('hotEvent') }}</span>
              <span>{{
                '· ' +
                $t('relatedArticleNum', {
                  num: NumUtil.formatNum(
                    item.ext.relatedMainTheme.stats.articleNum
                  )
                })
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.articles-item {
  display: flex;
  padding: 8px 0;
  cursor: pointer;
  text-decoration: none;
  align-items: center;
  gap: 16px;
}

.articles-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.articles-item .left {
  position: relative;
  width: 220px;
  height: 124px;
}

.sort-num {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 2px;
  font-size: 20px;
  font-weight: 600;
  color: white;
  width: 28px;
  height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-top-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.articles-item img {
  height: 124px;
  width: 220px;
  object-fit: cover;
  border-radius: 2px;
}

.articles-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.articles-title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.articles-desc {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 20px;
  margin-top: 8px;
  word-break: break-word;
}

.extra-info {
  display: flex;
  gap: 16px;
  margin-top: 8px;
}

.articles-source {
  font-size: 14px;
  font-weight: 600;
  color: #279ff6;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

.articles-time {
  font-size: 14px;
  font-weight: 600;
  color: #279ff6;
  flex-shrink: 0;
}

.relative-theme {
  display: flex;
  margin-left: auto;
  align-items: center;
  flex-shrink: 0;
}

.relative-theme:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.relative-theme img {
  width: 20px;
  height: 20px;
  margin-right: 3px;
  filter: invert(43%) sepia(42%) saturate(5313%) hue-rotate(333deg)
    brightness(90%) contrast(96%);
}

.relative-theme span:nth-child(2) {
  font-size: 14px;
  font-weight: 600;
  color: #f14749;
}

.relative-theme span:nth-child(3) {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 4px;
}

@media screen and (max-width: 999px) {
  .articles-item {
    gap: 6px;
  }

  .articles-item .left {
    width: 100px;
    height: 82px;
  }

  .sort-num {
    font-size: 12px;
    width: 20px;
    height: 26px;
  }

  .articles-item img {
    height: 82px;
    width: 100px;
  }

  .articles-title {
    font-size: 14px;
    font-weight: 700;
  }

  .articles-desc {
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
  }

  .extra-info {
    margin-top: 6px;
  }

  .articles-source {
    font-size: 12px;
    font-weight: 400;
  }

  .articles-time {
    font-size: 12px;
    font-weight: 400;
  }

  .relative-theme img {
    width: 16px;
    height: 16px;
  }

  .relative-theme span:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
  }

  .relative-theme span:nth-child(3) {
    display: none;
  }
}
</style>
